import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import './List.scss'

const List = () => {
  return (
    <Container>
      <Row>
        <Col>
          <Row className='pagefule-list-item'>
            <h1 className='pagefule-home--header pagefule-header--margin-2'>Our Picks List - July 2022</h1>
            <p className='pagefule-home--paragraph'>Want to read but don't know where to start? Always picking up a book and never stay motivated to finish? Well Pagefule is here to help. With Pagefule, easily find new books to read and create reading goals perfect for you. With a dashboard to see your progress and the ability to explore popular titles, Pagefule will help you become the reader you always wanted to be.</p>
          </Row>
        </Col>
      </Row>

      <Row className='pagefule-list-item'>
        <Col md={3}>
          <img src='/assets/book-cover-stock-2.jpg' className='pagefule-list--image' />
        </Col>
        <Col>
          <h1 className='pagefule-home--header pagefule-header--margin-2'>Book 1</h1>
          <p>I'm baby meh health goth portland fashion axe jean shorts tousled VHS. Gluten-free jean shorts cloud bread ramps cardigan etsy, pitchfork vape cronut meditation snackwave butcher chia. Ethical semiotics cold-pressed hammock iPhone, lumbersexual normcore craft beer street art celiac palo santo farm-to-table austin. Pop-up chartreuse iceland hella slow-carb 90's gluten-free occupy narwhal shabby chic.</p>
        </Col>
      </Row>

      <Row className='pagefule-list-item'>
        <Col md={3}>
          <img src='/assets/book-cover-stock-2.jpg' className='pagefule-list--image' />
        </Col>
        <Col>
          <h1 className='pagefule-home--header pagefule-header--margin-2'>Book 1</h1>
          <p>I'm baby meh health goth portland fashion axe jean shorts tousled VHS. Gluten-free jean shorts cloud bread ramps cardigan etsy, pitchfork vape cronut meditation snackwave butcher chia. Ethical semiotics cold-pressed hammock iPhone, lumbersexual normcore craft beer street art celiac palo santo farm-to-table austin. Pop-up chartreuse iceland hella slow-carb 90's gluten-free occupy narwhal shabby chic.</p>
        </Col>
      </Row>

      <Row className='pagefule-list-item'>
        <Col md={3}>
          <img src='/assets/book-cover-stock-2.jpg' className='pagefule-list--image' />
        </Col>
        <Col>
          <h1 className='pagefule-home--header pagefule-header--margin-2'>Book 1</h1>
          <p>I'm baby meh health goth portland fashion axe jean shorts tousled VHS. Gluten-free jean shorts cloud bread ramps cardigan etsy, pitchfork vape cronut meditation snackwave butcher chia. Ethical semiotics cold-pressed hammock iPhone, lumbersexual normcore craft beer street art celiac palo santo farm-to-table austin. Pop-up chartreuse iceland hella slow-carb 90's gluten-free occupy narwhal shabby chic.</p>
        </Col>
      </Row>
    </Container>
  )
}

export default List
