import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';

const Navigation = () => {
  return (
    <Navbar className='pagefule-navigation'>
      <Container>
        <Navbar.Brand href="/">Pagefule</Navbar.Brand>
        {/* <Navbar.Collapse className="justify-content-end">
          <Nav.Link href="#home">Explore</Nav.Link>
          <Nav.Link href="#features">Dashboard</Nav.Link>
          <Nav.Link href="#pricing">Goals</Nav.Link>
        </Navbar.Collapse> */}
      </Container>
    </Navbar>
  )
}

export default Navigation
