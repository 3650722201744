import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './Home.scss'

const Home = () => {
  return (
    <Container>
      <Row>
        <Col>
          <Row>
            <h1 className='pagefule-home--header pagefule-header--margin'>Find great books and keep up with your reading goals</h1>
            <p className='pagefule-home--paragraph'>Want to read but don't know where to start? Always picking up a book and never stay motivated to finish? Well Pagefule is here to help. With Pagefule, easily find new books to read and create reading goals perfect for you. With a dashboard to see your progress and the ability to explore popular titles, Pagefule will help you become the reader you always wanted to be.</p>
            <div className='pagefule-home--buttons'>
              {/* <Button variant="outline-dark" size="lg" className='pagefule-button--rounded'>Login</Button> */}
              <Button variant="outline-primary" size="lg" className='pagefule-button--rounded'>Explore</Button>
            </div>
          </Row>
        </Col>
        <Col>
          <img src='/assets/books.jpg' className='pagefule-home--image' />
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <h2 className='pagefule-home--header'>Explore</h2>
            <p className='pagefule-home--paragraph'>Need an idea of what to read next? Check out these lists with different book ideas.</p>
            <Col>
              <Link to='/list' className='pagefule-card-link'>
                <Card>
                  <Card.Body>
                    <Card.Title>Our Picks</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Our book picks for the month of July</Card.Subtitle>
                    <Card.Text>
                      Some quick example text to build on the card title and make up the bulk of
                      the card's content.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
            <Col>
              <Link to='/list' className='pagefule-card-link'>
                <Card>
                  <Card.Body>
                    <Card.Title>Popular Now</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Books that are popular now on the internet</Card.Subtitle>
                    <Card.Text>
                      Some quick example text to build on the card title and make up the bulk of
                      the card's content.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default Home
